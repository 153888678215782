<header class="header-wrapper">
    <div id="headerMenu" class="headerInner-wrapper d-flex align-items-center justify-content-between">
        <a href="javascript:void(0);" class="brand-logo mb-0">
            <img src="assets/images/logo.png" class="img-fluid" alt="brand-logo">
        </a>
        <!-- <div class="mobile-toggle d-flex d-md-none">
      <div class="header-toggle" (click)="sideBarClick()">
        <span class="fas fa-bars"></span>
      </div>
      <div class="header-toggle ml-4" (click)="searchOpen()">
        <span class="fas fa-ellipsis-h"></span>
      </div>
    </div> -->
        <div class="loginUserDetails">
            <div class="dropdown">
                <button class="btn loginUserDropdownBtn" type="button" id="loginUserDetails" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="user-welcome">Hi, </span>
          <span class="login-username Poppins-medium text-white">{{userName|titlecase}}</span>
          <span class="badge username-badge">{{userNameBadge}}</span>
        </button>
                <div class="dropdown-menu loginUserDropdown" aria-labelledby="loginUserDetails">
                    <a (click)="navbarcontrol('dashboard')" routerLinkActive="active" class="dropdown-item">
                        <span class="fas fa-tachometer-alt"></span> Dashboard
                    </a>
                    <a *ngIf="_apiService.checkAdmin();" (click)="navbarcontrol('dashboard/user-list')" class="dropdown-item">
                        <span class="fas fa-list"></span> User List
                    </a>
                    <a *ngIf="_apiService.checkAdmin();" (click)="navbarcontrol('dashboard/update-data');" class="dropdown-item">
                        <span class="far fa-edit"></span> Upload Data
                    </a>
                    <a (click)="navbarcontrol('dashboard/bookmark');" class="dropdown-item">
                        <span class="fas fa-bookmark"></span> Bookmarks
                    </a>
                    <a *ngIf="_apiService.checkAdmin();" (click)="navbarcontrol('dashboard/create-new-user');" class="dropdown-item">
                        <span class="fas fa-user-plus"></span> Create New User
                    </a>

                    <!-- <a class="dropdown-item" >
            <span class="fas fa-cog"></span>
            Settings
          </a> -->
                    <a (click)="navbarcontrol('dashboard/change-password');" class="dropdown-item">
                        <span class="fas fa-user-cog"></span> Change Password
                    </a>
                    <a (click)="logout()" class="dropdown-item">
                        <span class="fas fa-sign-out-alt"></span> Logout
                    </a>

                </div>
            </div>
        </div>
    </div>
    <!-- <div class="header-menu d-flex align-items-center justify-content-end justify-content-md-between" [ngClass]="{'search-open': showSearchFilter}">
    <div class="primary-menu" [ngClass]="{'sideBar-open': showSideBar}">
      <ul class="list-unstyled menu-items mb-0">
        <li class="menu-item position-relative active">
          <a href="javascript:void(0);" class="menu-link Poppins-medium">
            <span class="fas fa-tachometer-alt mr-2"></span>
            Dashboard</a>
        </li>
        <li class="menu-item position-relative">
          <a href="javascript:void(0);" class="menu-link Poppins-medium">
            <span class="fas fa-list mr-2"></span>
            User List</a>
        </li>
        <li class="menu-item position-relative">
          <a href="javascript:void(0);" class="menu-link Poppins-medium">
            <span class="far fa-edit mr-2"></span>
            Update Data</a>
        </li>
        <li class="menu-item position-relative">
          <a (click)="createNewUser()" href="javascript:void(0);" class="menu-link Poppins-medium">
            <span class="fas fa-user-plus"></span>
            Create New User
          </a>
        </li>
        <li class="menu-item position-relative mobile-menu">
          <a href="javascript:void(0);" class="menu-link Poppins-medium">
            <span class="fas fa-cog mr-2"></span>
            Settings
          </a>
        </li>
        <li class="menu-item position-relative mobile-menu">
          <a href="javascript:void(0);" class="menu-link Poppins-medium">
            <span class="fas fa-user-cog mr-2"></span>
            Change Password
          </a>
        </li>
        <li class="menu-item position-relative mobile-menu">
          <a href="javascript:void(0);" class="menu-link Poppins-medium">
            <span class="fas fa-sign-out-alt mr-2"></span>
            Logout
          </a>
        </li>
      </ul>
      <button class="close-btn position-absolute d-block d-md-none border-0" (click)="sideBarClose()">
        <span class="fas fa-times"></span>
      </button>
    </div>
    <div class="search-filter position-relative">
      <input type="text" placeholder="Search..." class="filter-search border-0">
      <span class="search-icon position-absolute">
        <i class="fas fa-search"></i>
      </span>
    </div>
  </div> -->
</header>
<!-- <div class="content">

</div> -->
<div class="sidePanel-wrapper position-fixed" [ngClass]="{'OpenSidePanel': navbarDisplay}">
    <div class="sidePanel-content" light malihu-scrollbar [scrollbarOptions]="darkScrollbarOptions">
        <!-- <p>Treatment Options AnxietyLorazepam
      Print Share
      Lorazepam
      Generic Name: lorazepam (oral) (lor A ze pam)
      Brand Names: Ativan

      Medically reviewed by Sophia Entringer, PharmD Last updated on Feb 7, 2020.

      OverviewSide EffectsDosageProfessionalTipsInteractionsMore
      What is lorazepam?

      Lorazepam belongs to a group of drugs called benzodiazepines. It affects chemicals in the brain that may be
      unbalanced in people with anxiety.

      Lorazepam is used to treat anxiety disorders and seizure disorders.

      It is dangerous to purchase lorazepam on the Internet or outside the United States. The sale and distribution of
      medicines outside the U.S. does not comply with safe-use regulations of the Food and Drug Administration (FDA).

      These medications may contain dangerous ingredients, or may not be distributed by a licensed pharmacy.</p> -->
        <router-outlet></router-outlet>
    </div>
    <button (click)="overlayClick()" class="close-btn position-absolute border-0">
    <span class="fas fa-times"></span>
  </button>
</div>

<div class="sidePanel-overlay position-fixed" (click)="overlayClick()"></div>