export const APIs = {
  registerApi: "http://db.goek-warengruppen.de/api/user/register",
  loginApi: "http://db.goek-warengruppen.de/api/user/login",
  DeleteApi: "http://db.goek-warengruppen.de/api/user/deleteuser/",
  getUsers: "http://db.goek-warengruppen.de/api/user/getusers",
  change_password: "http://db.goek-warengruppen.de/api/user/change_password",
  forgot_password: "http://db.goek-warengruppen.de/api/user/forgot_password",
  getRole: "http://db.goek-warengruppen.de/api/user/role",
  addUser: "http://db.goek-warengruppen.de/api/user/adduser",
  logout: "http://db.goek-warengruppen.de/api/user/logout",
  uploadproducts:"http://db.goek-warengruppen.de/api/user/uploadproducts",
  updateUser:"http://db.goek-warengruppen.de/api/user/updateuser/",
  getProducts:"http://db.goek-warengruppen.de/api/user/getproducts",
  forgot_passwordVerify: "http://db.goek-warengruppen.de/api/user/forgot_password/verify",
  Bookmark:"http://db.goek-warengruppen.de/api/user/bookmark"
}
