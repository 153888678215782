import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerModule,NgxSpinnerService } from "ngx-spinner";

const TOKEN_HEADER_KEY = "Authorization";

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  public count=0;

  constructor(private _apiservice: ApiService, private _router: Router, public toaster: ToastrService,public spinner: NgxSpinnerService) { }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    console.log("we are in interceptor error handler")
    if (err.status === 401 || err.status === 403) {
      localStorage.clear();
      this._router.navigateByUrl('/login');
      return throwError (err.error);
    }
    if(err.status===500){
      this.toaster.warning("Internal server error");
      console.log("INternal server Error",err.error);
    }
    return throwError(err.error);

  }

  intercept(req: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>>  {
    this.spinner.show();
    this.count++;
    let authReq = req;
    const data = this._apiservice.gettoken();
    const token=JSON.parse(data);


    if (token != null) {
      // console.log("interceptor is working")
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer' + token.data.access_token) })
    }
    return next.handle(authReq).pipe(
      tap(
        event => {
          //console.log(event),
        },
        error => {
          //console.log(error)
        }
      ), finalize(() => {
        this.count--;
        // console.log("onresponse",this.count)
        if (this.count === 0) {
          // console.log("closing")
          this.spinner.hide()
        }
        else {
          this.spinner.show();
        }
      }),
      catchError(error => this.handleAuthError(error)));
  }
}
