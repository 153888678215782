import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-data',
  templateUrl: './update-data.component.html',
  styleUrls: ['./update-data.component.scss']
})
export class UpdateDataComponent implements OnInit {

  constructor(public _apiService: ApiService,
    private _toaster: ToastrService,
    private _router: Router) { }

  public filename: string;
  public filetoUpload: File;
  public button: boolean = false;
  public fileSelectError: boolean = false;
  public fileExtension: string;
  public fileSize: number;



  ngOnInit(): void {
    document.body.classList.remove('loaderclass');
    this.button = false;
    this.filename = null;
    this.filetoUpload = null;
  }

  // to select file from system storage

  public upload(file: FileList): void {
    console.log(file)
    this.filetoUpload = file.item(0);
    if (this.filetoUpload !== null) {
      this.fileSize = this.filetoUpload.size;
      this.filename = this.filetoUpload.name;
      this.fileExtension = this.filename.substring(this.filename.lastIndexOf('.') + 1);
      this.fileSelectError = false;
    } else {
      this.filename = "";
      this.fileSelectError = true;
    }
  }
  public submitFile(): void {
    var size = ((this.fileSize / 1024) / 1024)
      if (this.filetoUpload === null && size < 10) {
        console.log("please select file");
        this.fileSelectError = true;
      }
      else if (this.fileExtension === 'xlsx' || this.fileExtension == 'xls') {
        let formdata = new FormData;
        formdata.append('file', this.filetoUpload);
        console.log(this.filetoUpload)
        this.button = true;
        document.body.classList.add('loaderclass')
        try {
          this._apiService.uploadProducts(formdata).subscribe(response => {
            console.log("update data", response);
            this._toaster.success("File Uploaded Successfully")
            this.ngOnInit();
            this._apiService.uploadproduct.next(true);

            this._router.navigateByUrl('/dashboard');
          }, error => {
            console.log(error);
            this._toaster.error("file upload failed")
            // this.ngOnInit();
            this.button = false;
          })

        } catch (error) {
          console.log(error)
        }

      }
      else {
        this._toaster.error("Invalid File! Accepts only xls OR xlsx type files")
        this.ngOnInit();
      }
    }

}
