<!-- <p>create-new-user works!</p> -->
<div class="create-wrapper">
    <div class="create-header text-center">
        <h4 class="form-title">Create New User</h4>
    </div>
    <form [formGroup]="newUserForm">
        <div class="form-group position-relative">
            <input type="email" placeholder="Email" required formControlName="email" class="form-control border-0 ">
            <div *ngIf="form.email.invalid && formerror" class="text-danger text-small text-left d-block pl-4">
                <span class="Poppins-medium error-message" *ngIf="form.email.errors?.required"> {{errorMessage.requiredError}}</span>
                <span class="Poppins-medium error-message" *ngIf="form.email.errors?.email">Please enter a valid Email</span>
            </div>
        </div>
        <div class="form-group position-relative">
            <input type="text" placeholder="Name" required formControlName="name" class="form-control border-0 ">
            <div *ngIf="form.name.invalid && formerror" class="text-danger text-small text-left d-block pl-4">
                <span class="Poppins-medium error-message" *ngIf="form.name.errors?.required">{{errorMessage.requiredError}}</span>
            </div>
        </div>
        <div class="form-group position-relative">
            <input type="password" placeholder="Password" required formControlName="password" class="form-control border-0 ">
            <div *ngIf="form.password.invalid && formerror" class="text-danger text-small text-left d-block pl-4">
                <span class="Poppins-medium error-message" *ngIf="form.password.errors.required "> {{errorMessage.requiredError}}</span>
                <span class="Poppins-medium error-message" *ngIf="form.password.errors?.minlength">{{errorMessage.PasswordLengthError}}</span>
                <span class="Poppins-medium error-message" *ngIf="!form.password.errors?.minlength && form.password.errors?.pattern">{{errorMessage.passwordError}}</span>
            </div>
        </div>
        <div class="form-group position-relative">
            <input type="password" placeholder="Confirm Password" required formControlName="confirmPassword" class="form-control border-0 ">
            <div *ngIf="form.confirmPassword.invalid && formerror" class="text-danger text-small text-left d-block pl-4">
                <span class="Poppins-medium error-message" *ngIf="form.confirmPassword.errors?.required">{{errorMessage.requiredError}}</span>
                <span class="Poppins-medium error-message" *ngIf="form.confirmPassword.errors?.passwordMismatch">{{errorMessage.PasswordMatchError}}</span>
            </div>
        </div>
        <!-- <div class="dropdown mt-3 user-role">
            <button type="button" class="btn dropdown-toggle form-control border-0" data-toggle="dropdown">
              {{selectedRoleLabel}}
            </button>
            <div class="dropdown-menu w-100">
                <div *ngFor=" let role of roles">
                    <a class="dropdown-item" (click)="selectedRole(role)">{{role.name}}</a>
                </div>
            </div>
        </div> -->
        <div class="form-group position-relative">
            <select class="custom-select form-control border-0" formControlName="role">
                <option [ngValue]="null">Choose role</option>
                <option *ngFor="let role of roles" [ngValue]="role.id">{{role.name}}</option>
            </select>
            <span class="position-absolute select-caret fas fa-caret-down"></span>
            <div *ngIf="form.role.invalid && formerror" class="text-danger text-small text-left d-block pl-4">
                <div class="text-danger text-small text-left d-block Poppins-medium error-message" *ngIf="formerror && form.role.errors?.required">
                    {{errorMessage.Roleerror}}
                </div>
            </div>
        </div>
    </form>
    <button [ngClass]="{'spinner-open': button}" [disabled]="button" type="submit" class="btn create-button Poppins-medium w-100 d-block mt-5" (click)="newuser()">
        <span>Create User</span>
         <div class="loader ml-3"></div>
    </button>
</div>