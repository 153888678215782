import { Component, OnInit, VERSION, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, RouterLinkActive } from '@angular/router';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ToasterconfigService } from '../services/toasterconfig.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {


  public showSideBar: boolean = false;
  public showSearchFilter: boolean = false;
  public angularVersion: string = VERSION.full;
  public ngxMalihuScrollbarVersion: string = '9.0.0';
  public darkScrollbarOptions: object = { axis: 'y', theme: 'dark' };
  public navbarDisplay: boolean = false;
  public userName:string;
  public userNameBadge:string;



  constructor(private _router: Router,
    private mScrollbarService: MalihuScrollbarModule,
    private _activatedroute: ActivatedRoute,
    public _apiService: ApiService,
    private _toaster: ToastrService,
    private _toasterconfig: ToasterconfigService) {

  }


  ngOnInit() {
    var user=localStorage.getItem('token');
    var userdat=JSON.parse(user);
    this.userName=userdat.data.user.name;
    // console.log("username is",this.userName)
    this.userNameBadge=this.userName.charAt(0).toUpperCase();
  }
  ngAfterViewInit() {


  }
  // funtion to logout the user

  public logout(): void {
    const token = this._apiService.gettoken();
    // console.log(this._apiService.gettoken());

    try {
      this._apiService.logOut({ "token": token }).subscribe(Response => {
        console.log(Response);
        if (Response) {
          localStorage.clear();
          this._toaster.success("You are logged out successfully ");
          this._router.navigateByUrl('/login');
        }
      }, error => {
        console.log(error);
        // this._toaster.warning("Logout not successfull")
      })
    }
    catch (error) {
      console.log(error);
    }
  }
  ngDoCheck() {
    if (this._router.url !== "/dashboard") {
      this.navbarDisplay = true;
      document.body.classList.add("OpenSidePanel");
    }
    else {
      this.navbarDisplay = false;
      document.body.classList.remove("OpenSidePanel");
    }
  }


  // function to navigate route on user requset

  public navbarcontrol(routerurl: string): void {
    const presentroute = routerurl;
    if (presentroute == "dashboard") {
      this.navbarDisplay = false;
      this._router.navigate([presentroute]);
      document.body.classList.remove("OpenSidePanel");
    }
    else {
      this.navbarDisplay = true;
      this._router.navigate([presentroute]);
      document.body.classList.add("OpenSidePanel");

    }
  }
  // function to  hide side panel on overlay click

  public overlayClick(): void {
    this.navbarDisplay = false;
    this._router.navigateByUrl('dashboard')
    document.body.classList.remove("OpenSidePanel");
  }


}
