<div class="login-wrapper">
    <div class="form-container">
        <div class="brand-logo text-center">
            <img src="assets/images/logo.png" class="img-fluid" alt="brand-logo">
        </div>
        <div class="login-signin">
            <div class="login-header">
                <h3 class="form-title text-center Poppins-medium">Login</h3>
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="signIn()" name="login-form" class="login-form" autocomplete="off">
                <div class="form-group position-relative">
                    <input type="email" placeholder="Email" required class="form-control border-0 mb-2" formControlName="email">
                    <div *ngIf="form.email.invalid && formError" class="text-danger text-small text-left d-block pl-4">
                        <span class="Poppins-medium error-message" *ngIf="form.email.errors?.required">{{FormMessage.requiredError}}</span>
                        <span class="Poppins-medium error-message" *ngIf="form.email.errors?.email">{{FormMessage.emailError}}</span>
                    </div>
                </div>
                <div class="form-group position-relative">
                    <input type="password" name="pass" placeholder="Password" required class="form-control border-0 mb-2" formControlName="password">
                    <div *ngIf="form.password.invalid && formError" class="text-danger text-small text-left d-block pl-4">

                        <span class="Poppins-medium error-message" *ngIf="form.password.errors.required ">
              {{FormMessage.requiredError}}</span>
                        <span class="Poppins-medium error-message" *ngIf="form.password.errors?.minlength">{{FormMessage.PasswordLengthError}}</span>
                        <span class="Poppins-medium error-message" *ngIf="!form.password.errors?.minlength && form.password.errors?.pattern">{{FormMessage.passwordError}}</span>
                    </div>
                </div>
                <div class="login-extra d-flex justify-content-end text-center">
                    <!-- <label class="checkbox d-inline position-relative text-left">
                        <input type="checkbox" name="remember" class="position-absolute"> Remember me
                        <span class="position-absolute"></span>
                    </label> -->
                    <a routerLink="/forgot-password" routerLinkActive="active" class="login-forget-link">Forgot Password ?</a>
                </div>
                <div class="login-actions text-center">
                    <button [ngClass]="{'spinner-open': button}" [disabled]="button" type="submit" class="btn btn-brand btn-pill default-btn d-flex align-items-center justify-content-center mx-auto">
            <span>Sign In</span>
            <div class="loader ml-3"></div>
          </button>
                </div>
            </form>
        </div>
    </div>
</div>