import { Injectable } from '@angular/core';
import { ToastrService, IndividualConfig } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToasterconfigService {

  public options:IndividualConfig
  constructor(private _toaster:ToastrService) {
    this.options=this._toaster.toastrConfig;
    // this.options.positionClass="toast-top-center";
    this.options.timeOut=1500;
    this.options.closeButton=true;
   }
   showToast(title,message,type){
     this._toaster.show(message,title,this.options,'toast-'+type);
   }
}
