import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FormvalidationService } from 'src/app/shared/services/formvalidation.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { Router } from '@angular/router';
import { ErrorMessage } from '../../../../assets/data/error';


@Component({
  selector: 'app-create-new-user',
  templateUrl: './create-new-user.component.html',
  styleUrls: ['./create-new-user.component.scss']
})
export class CreateNewUserComponent implements OnInit, AfterViewInit {

  public formerror: boolean;
  public userData: object;
  public newUserForm: FormGroup;
  public roleResponseData: any;
  public roles: any;
  public roleselected;
  public button: boolean = false;
  public selectedRoleLabel = "Role";
  public errorMessage: any = ErrorMessage;


  constructor(private _toaster: ToastrService, private _fb: FormBuilder,
    private _fromvalidationservice: FormvalidationService,
    private _apiService: ApiService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    document.body.classList.remove('loaderclass');
    this.button = false;
    this.formerror = false;
    this.newUserForm = this._fb.group({
      email: new FormControl(null, [Validators.required, Validators.email]),
      name: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required,
      Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d).+$/), Validators.minLength(8)]),
      confirmPassword: new FormControl(null, [Validators.required]),
      role: new FormControl(null, [Validators.required])
    },
      {
        validator: this._fromvalidationservice.MatchPassword('password', 'confirmPassword')
      }
    );
  }
  ngAfterViewInit() {
    this._apiService.getRoles().subscribe(response => {
      this.roleResponseData = response;
      console.log("user roles", this.roleResponseData.data);
      this.roles = this.roleResponseData.data;
    })

  }

  get form() {
    return this.newUserForm.controls;
  }
  public newuser(): void {
    this.formerror = true;
    if (this.newUserForm.valid) {
      this.button = true;
      try {
        this.userData = { "name": this.newUserForm.value.name, "email": this.newUserForm.value.email, "password": this.newUserForm.value.password, "roleId": this.newUserForm.value.role }
        console.log(this.userData);
        this._apiService.addUser(this.userData).subscribe(response => {
          console.log(response);
          if (response) {
            this._toaster.success("User Created Successfully");
            this.ngOnInit();
            this.button = false;
          }
        }, error => {
          console.log(error);
          this.button = false;
          this._toaster.error("The email has already been taken.");
          document.body.classList.remove('loaderclass');
        })
      } catch (error) {
        console.log(error);
      }
    }
  }
  selectedRole(data) {
    this.selectedRoleLabel = data.name;
  }

}
