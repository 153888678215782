import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { User, LoginUser, ChangePassword, Token, Product, PasswordVerify } from './interface';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { APIs } from '../../shared/constant'
@Injectable({
  providedIn: 'root'
})

export class ApiService {
  public uploadproduct=new Subject();
  public uploadprduct$ =this.uploadproduct.asObservable();

  public bookmarkSelected =new Subject();
  public bookmarkSelected$=this.bookmarkSelected.asObservable();

  constructor(private _http: HttpClient) { }

  //method to call get users API
  public getusers(perpage:number,page:number): Observable<object> {
    return this._http.get<object>(APIs.getUsers+"?perPage="+perpage+"&page="+page).pipe(catchError(this.errorHandler));

  }

  //method to call get products API
  public getProducts(perPage:any,pageno:any): Observable<Product[]> {
    return this._http.get<Product[]>(APIs.getProducts+"?perPage="+perPage+"&page="+pageno).pipe(catchError(this.errorHandler));
  }
  public getProductsByPage(perpage:number,page:number,search?:any,sortcolumn?:any,sortBy?:string): Observable<Product[]>{
    return this._http.get<Product[]>(APIs.getProducts+"?search="+search+"&perPage="+perpage+"&page="+page+"&sortBy"+sortcolumn+"="+sortBy).pipe(catchError(this.errorHandler));

  }

  // method to call Register User API

  public addUser(userdata: object): Observable<User> {
    return this._http.post<User>(APIs.addUser, userdata).pipe(catchError(this.errorHandler));
  }

  // method to call Change password API

  public changepassword(userChangeData: object): Observable<ChangePassword> {
    return this._http.post<ChangePassword>(APIs.change_password, userChangeData).pipe(catchError(this.errorHandler));
  }

  // method to call Forget Password API

  public forgetPassword(email: object): Observable<object> {
    console.log(email)
    return this._http.post(APIs.forgot_password, email).pipe(catchError(this.errorHandler));

  }

  //method to call forgetpassword verify api

  public forgetPasswordVerify(data: object): Observable<PasswordVerify> {
    return this._http.post<PasswordVerify>(APIs.forgot_passwordVerify,data).pipe(catchError(this.errorHandler));
  }

  //method to call user login API
  public userlogin(userdata: object): Observable<LoginUser> {
    return this._http.post<LoginUser>(APIs.loginApi, userdata).pipe(catchError(this.errorHandler));

  }

  // Method to call  user Delete API
  public deleteUser(id: number): Observable<object> {
    return this._http.delete(APIs.DeleteApi + id).pipe(catchError(this.errorHandler));
  }

  //method to get types of user roles
  public getRoles(): Observable<object> {
    return this._http.get(APIs.getRole).pipe(catchError(this.errorHandler));
  }

  //method to update data

  public uploadProducts(file: any) {
    return this._http.post(APIs.uploadproducts, file).pipe(catchError(this.errorHandler));
  }

  //method to call update api

  public updateUser(id: number, userdata: object) {
    return this._http.put(APIs.updateUser + id, userdata).pipe(catchError(this.errorHandler));
  }

  public bookMark(title:string,url:string){
    return this._http.post(APIs.Bookmark,{"title":title,"url":url}).pipe(catchError(this.errorHandler));
  }

  public bookMarkGet(perPage,pageno){
    return this._http.get(APIs.Bookmark+"?perPage="+perPage+"&page="+pageno).pipe(catchError(this.errorHandler));
  }
  public updateBookmark(id:number,data:object){
    return this._http.put(APIs.Bookmark+"/"+id,data).pipe(catchError(this.errorHandler));
  }

  public deleteBookmark(id:number){
    return this._http.delete(APIs.Bookmark+"/"+id).pipe(catchError(this.errorHandler))
  }

  // method to call logout Api

  public logOut(token: object) {
    return this._http.post<Token>(APIs.logout, token).pipe(catchError(this.errorHandler));

  }

  // method  to check token is there in localstorage
  public loggedIn(): boolean {

    return !!localStorage.getItem('token');
  }
  public checkAdmin(): boolean {
    var  data = localStorage.getItem('token');
    var token = JSON.parse(data);
    if (token.data.user.roleType === "Admin") {
      return true;
    } else {
      return false;
    }
  }
  //method to get token from local storage
  public gettoken() {

    return localStorage.getItem('token');
  }




  // method to hande errors while calling APIs

  private errorHandler(error: HttpErrorResponse) {

    console.log(" error in service api call", error);
    return throwError(error);
  };
}
