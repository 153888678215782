<div class="change-wrapper">
    <div class="change-header text-center">
        <h4 class="form-title">Change Password</h4>
    </div>
    <form [formGroup]="changePasswordForm" (ngSubmit)="passwordChange()" class="form-group">
        <div class="form-group position-relative">
            <input type="password" placeholder="Current Password" required formControlName="currentPassword" class="form-control border-0 mb-2 ">
            <div *ngIf="form.currentPassword.invalid&&formError" class="text-danger text-small text-left d-block pl-4">
                <span class="Poppins-medium error-message" *ngIf="form.currentPassword.errors?.required"> Current Password is required</span>
                <span class="Poppins-medium error-message" *ngIf="form.currentPassword.errors?.pattern">Current Password is Invalid</span>
            </div>
        </div>
        <div class="form-group position-relative">
            <input type="password" placeholder="New Password" required formControlName="newPassword" class="form-control border-0 mb-2 ">
            <div *ngIf="(form.newPassword.invalid && formError)" class="text-danger text-small text-left d-block pl-4">
                <span class="Poppins-medium error-message" *ngIf="form.newPassword.errors.required "> {{formMessage.requiredError}}</span>
                <span class="Poppins-medium error-message" *ngIf="form.newPassword.errors?.minlength">{{formMessage.PasswordLengthError}}</span>
                <span class="Poppins-medium error-message" *ngIf="!form.newPassword.errors?.minlength && form.newPassword.errors?.pattern">{{formMessage.passwordError}}</span>
            </div>
        </div>
        <div class="form-group position-relative">
            <input type="password" placeholder="Confirm Password" required formControlName="newConfirmPassword" class="form-control border-0 mb-2 ">
            <div *ngIf="form.newConfirmPassword.invalid && formError" class="text-danger text-small text-left d-block pl-4">
                <span class="Poppins-medium error-message" *ngIf="form.newConfirmPassword.errors?.required">{{formMessage.requiredError}}</span>
                <span class="Poppins-medium error-message" *ngIf="form.newConfirmPassword.errors?.passwordMismatch">{{formMessage.PasswordMatchError}}</span>
            </div>
        </div>
        <button [ngClass]="{'spinner-open': button}" [disabled]="button" type="submit" class="btn change-button Poppins-medium  w-100 d-block mt-5">
            <span>Change Password</span>
             <div class="loader ml-3"></div>
        </button>
    </form>
</div>