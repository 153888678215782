import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.scss']
})
export class BookmarksComponent implements OnInit, AfterViewInit {
  @ViewChild(NgScrollbar) scrollRef: NgScrollbar;



  public darkScrollbarOptions: object = { axis: 'x', theme: 'dark' };
  public lightScrollbarOptions: object = { axis: 'yx', theme: 'light' };
  public modalRef: BsModalRef;
  public bookMarks: any;
  public successdata: any;
  public UpdatedBookmarkTitle: string;
  public updatedBookmarkUrl: string;
  public updatedBookmarkId: number;
  public url:string;
  public perpage: number = 50;
  public page: number = 1;
  public index: number = 1;
  public viewPageNO: number = 1;
  public curretpageHitApi: number = 1;

  constructor(private _apiservice: ApiService,
    private modalService: BsModalService,
    private mScrollbarService: MalihuScrollbarModule,
    private _toaster: ToastrService,
    private _router:Router

  ) { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.getbookmarks();
  }
  public decline(): void {
    this.modalRef.hide();

  }
  public getbookmarks() {
    try {
      this._apiservice.bookMarkGet(this.perpage,this.page).subscribe(response => {
        console.log(response)
        this.successdata = response;
        this.bookMarks = this.successdata.data;
      }, error => {
        console.log(error);
      })

    }
    catch (error) {
      console.log(error);
    }
  }
  public editModal(editmodal: TemplateRef<any>, bookMark: any): void {
    this.modalRef = this.modalService.show(editmodal, { class: 'modal-md editUserModal' });
    this.updatedBookmarkId = bookMark.id;
    this.updatedBookmarkUrl = bookMark.url;
    this.UpdatedBookmarkTitle = bookMark.title;
    // console.log(this.updatedBookmarkId,this.updatedBookmarkUrl,this.UpdatedBookmarkTitle);
  }
  public deleteModal(deleteModal: TemplateRef<any>): void {

    this.modalRef = this.modalService.show(deleteModal, { class: 'modal-sm deleteUserModal' });

  }
  public updateBookmark(): void {
    if (this.UpdatedBookmarkTitle == "" || this.updatedBookmarkUrl == "") {
      this._toaster.warning("Title or Url should not empty")
    } else
      try {
        this._apiservice.updateBookmark(this.updatedBookmarkId, { "title": this.UpdatedBookmarkTitle, "url": this.updatedBookmarkUrl })
          .subscribe(response => {
            console.log(response);
            this._toaster.success("BookMark Updated successfully!");
            this.modalRef.hide();
            this.getbookmarks();
          }, error => {
            console.log(error);
            this._toaster.error("bookmark Not Updated Successfully");
          })
      }
      catch (error) {
        console.log(error);
      }


  }

  public deleteBookmark(deleteId: number): void {

    console.log(deleteId);
    try {
      this._apiservice.deleteBookmark(deleteId).subscribe(
        response => {
          console.log(response);
          this._toaster.success("Bookmark Delete Successfully");
          this.modalRef.hide();
          this.getbookmarks();
        }, error => {
          console.log(error);
          this._toaster.error("bookmark not Deleted");
        }
      )
    }
    catch (error) {
      console.log(error);
    }
  }

  public bookmarkSelected(bookmark){
    console.log("its working");
    this.url=bookmark.url;
    let search = this.url.substring(this.url.lastIndexOf('=')+1);
    console.log(search);
    this._apiservice.bookmarkSelected.next({"search":search,"boolean":true});
    this._router.navigateByUrl('/dashboard');
  }
  public pagechanged(event) {
    console.log(event)
    this.viewPageNO = event;
    if (event == 5* this.index && event > this.curretpageHitApi) {
      this.curretpageHitApi = event;
      this.page = this.page + 1;
      this._apiservice.bookMarkGet(this.perpage, this.page).subscribe(response => {
        console.log(response);
        this.successdata = response;
        let data = this.successdata.data;
        this.successdata = [...this.successdata, ...data];
        this.index++;
      })
    }
  }
}
