import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './core/login/login.component';
import { ForgotPasswordComponent } from './core/forgot-password/forgot-password.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { HeaderComponent } from './shared/header/header.component';
import { ChangePasswordComponent } from './shared/change-password/change-password.component';
import { SaveSearchComponent } from './shared/save-search/save-search.component';
import { CreateNewUserComponent } from './features/dashboard/create-new-user/create-new-user.component';
import { UpdateDataComponent } from './features/dashboard/update-data/update-data.component';
import { UserListComponent } from './features/dashboard/user-list/user-list.component';
import { DeleteExistingUserComponent } from './features/dashboard/delete-existing-user/delete-existing-user.component';
import { ApiService } from './shared/services/api.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { ModalModule } from 'ngx-bootstrap';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {NgxPaginationModule} from 'ngx-pagination';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AuthGuard } from './shared/services/auth.guard';
import { TokenInterceptorService } from './shared/services/token-interceptor.service';
import { RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './features/page-not-found/page-not-found.component';
import { ToasterconfigService } from './shared/services/toasterconfig.service';
import  {  ScrollingModule  }  from  '@angular/cdk/scrolling';
import { BookmarksComponent } from './features/dashboard/bookmarks/bookmarks/bookmarks.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxSpinnerModule,NgxSpinnerService } from "ngx-spinner";


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    HeaderComponent,
    ChangePasswordComponent,
    SaveSearchComponent,
    CreateNewUserComponent,
    UpdateDataComponent,
    UserListComponent,
    DeleteExistingUserComponent,
    PageNotFoundComponent,
    BookmarksComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    MalihuScrollbarModule.forRoot(),
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    Ng2SearchPipeModule,
    NgxPaginationModule,
    AutocompleteLibModule,
    NgxDatatableModule,
    RouterModule,
    ScrollingModule,
    NgScrollbarModule,
    NgxSpinnerModule
  ],
  providers: [ApiService,AuthGuard,ToasterconfigService,
  {
    provide:HTTP_INTERCEPTORS,
    useClass:TokenInterceptorService,
    multi:true
  }],
  bootstrap: [AppComponent],
  entryComponents: [ChangePasswordComponent]
})
export class AppModule { }
