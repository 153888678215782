<!-- <p>forgot-password works!</p> -->
<div class="forgot-wrapper">
    <div class="form-container">
        <div class="brand-logo text-center">
            <img src="assets/images/logo.png" class="img-fluid" alt="brand-logo">
        </div>
        <div *ngIf="forgetformDisplay">
            <div class="forgot-signin">

                <div class="forgot-header  text-center">
                    <h4 class="form-title text-center Poppins-medium">Forgot Password</h4>
                    <!-- <h3 class="form-title text-center Poppins-medium">Enter your email to reset your password</h3> -->
                </div>
                <form [formGroup]="forgetPasswordForm" (ngSubmit)="request()" name="forgot-form" class="forgot-form">
                    <label for="reset" class="reset-label text-center d-block">Enter your email to reset your password</label>
                    <div class="form-group position-relative">
                        <input type="email" name="email" required placeholder="Email" required class="form-control border-0 mb-2" formControlName="email">
                        <div *ngIf="form.email.invalid && formError" class="text-danger text-small text-left d-block pl-4">
                            <span *ngIf="form.email.errors?.required && formError">{{formMessage.requiredError}} </span>
                            <span *ngIf="form.email.errors?.email">{{formMessage.emailError}}</span>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-center forget-btn-sec">
                        <button [ngClass]="{'spinner-open': button}" [disabled]="button" type="submit" class="btn btn-brand btn-pill default-btn">
              <span>Request</span>
              <div class="loader ml-3"></div>
            </button>
                        <!-- <button [ngClass]="{'spinner-open': button}" [disabled]="button" type="submit" class="btn btn-brand btn-pill default-btn ">Request</button> -->
                        <button routerLink="/login" routerLinkActive="active" class="btn btn-brand btn-pill cancel-btn ml-3">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-------------------------------------------------------------------------------------------------------------->
    <div *ngIf="!forgetformDisplay">
        <div class="form-container">

            <div class="forgot-header  text-center">
                <h4 class="form-title text-center Poppins-medium">Forgot Password Verify</h4>
                <!-- <h3 class="form-title text-center Poppins-medium">Enter your email to reset your password</h3> -->
            </div>
            <div>
                <form [formGroup]="forgetPasswordVerifyFrom" (ngSubmit)="verify()" class="forgot-form">
                    <label for="reset" class="reset-label text-center d-block">Enter your otp to set your password</label>
                    <div class="form-group position-relative">
                        <input type="email" name="email" disabled required placeholder="Email" required class="form-control border-0 mb-2" formControlName="email">
                        <div *ngIf="forgetForm.email.invalid && formError" class="text-danger text-small text-left d-block pl-4">
                            <span *ngIf="forgetForm.email.errors?.required && formError">{{formMessage.requiredError}} </span>
                            <span *ngIf="forgetForm.email.errors?.email">{{formMessage.emailError}}</span>
                        </div>
                    </div>
                    <div class="form-group position-relative">
                        <input type="password" name="otp" required placeholder="Enter Your OTP" required class="form-control border-0 mb-2" formControlName="otp">
                        <div *ngIf="forgetForm.otp.invalid && formError" class="text-danger text-small text-left d-block pl-4">
                            <span *ngIf="forgetForm.otp.errors?.required && formError">{{formMessage.requiredError}} </span>
                        </div>
                    </div>
                    <div class="form-group position-relative">
                        <input type="password" name="pass" placeholder="New Password" required class="form-control border-0 mb-2" formControlName="newPassword">
                        <div *ngIf="forgetForm.newPassword.invalid && formError" class="text-danger text-small text-left d-block pl-4">
                            <span class="Poppins-medium error-message" *ngIf="forgetForm.newPassword.errors.required ">
                {{formMessage.requiredError}}</span>
                            <span class="Poppins-medium error-message" *ngIf="forgetForm.newPassword.errors?.minlength">{{formMessage.PasswordLengthError}}</span>
                            <span class="Poppins-medium error-message" *ngIf="!forgetForm.newPassword.errors?.minlength && forgetForm.newPassword.errors?.pattern">{{formMessage.passwordError}}</span>
                        </div>
                    </div>
                    <div class="form-group position-relative">
                        <input type="password" placeholder="Confirm Password" required formControlName="newConfirmPassword" class="form-control border-0 mb-2 ">
                        <div *ngIf="forgetForm.newConfirmPassword.invalid && formError" class="text-danger text-small text-left d-block pl-4">
                            <span class="Poppins-medium error-message" *ngIf="forgetForm.newConfirmPassword.errors?.required">{{formMessage.requiredError}}</span>
                            <span class="Poppins-medium error-message" *ngIf="forgetForm.newConfirmPassword.errors?.passwordMismatch">{{formMessage.PasswordMatchError}}</span>
                        </div>
                    </div>

                    <button type="submit" class="btn btn-brand btn-pill default-btn">
            <span>Submit</span>
            <div class="loader ml-3"></div>
          </button>
                    <button routerLink="/login" routerLinkActive="active" class="btn btn-brand btn-pill cancel-btn ml-3">Cancel</button>

                </form>
            </div>
        </div>
    </div>
</div>