<!-- <p>update-data works!</p> -->
<div class="update-wrapper">
    <div class="update-heading">
        <h4 class="form-title">Upload File</h4>
    </div>
    <form class="update-form mb-4">
        <div class="d-flex justify-content-between align-items-center position-relative">
            <div class="uplodedFileName">
                <span>{{filename}}</span>
            </div>
            <div class="">
                <input type="file" id="uploadFile" class="uploadFile" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" (change)="upload($event.target.files)">
                <label for="uploadFile" class="mb-0 update-button text-uppercase text-white Poppins-medium">Upload</label>
            </div>
            <div *ngIf="fileSelectError" class="text-danger text-small text-left d-block Poppins-medium error-message mb-1">
                Please Select File
            </div>
            <div *ngIf="fileSize>1024*1024*10 && fileSelectError==false" class="text-danger text-small text-left d-block Poppins-medium error-message mb-1">
                File Size should not Exceed 10MB </div>
        </div>
    </form>
    <!-- <div *ngIf="fileSelectError"> Please Select File</div> -->

    <button [ngClass]="{'spinner-open': button}" [disabled]="button" type="button" class="btn update-button text-white Poppins-medium w-100 d-block" (click)="submitFile()">
        <span>Submit</span>
         <div class="loader ml-3"></div>
    </button>
</div>