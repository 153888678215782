<!-- <p>user-list works!</p> -->
<div class="user-table-wrapper">
    <h4 class="form-title text-center mb-4">User List</h4>
    <!-- <div class="scroll-table" light malihu-scrollbar [scrollbarOptions]="darkScrollbarOptions"> -->
    <!-- <cdk-virtual-scroll-viewport itemSize="75" style="height:740px" (scrolledIndexChange)="onscroll($event)"> -->

    <table class="table table-borderless">
        <thead>
            <tr>
                <th class="text-center">Email</th>
                <th class="text-center">Role</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let user of userdata|paginate:{ itemsPerPage: 10, currentPage: viewPageNO } ;let i=index">
                <td>
                    <button (click)="editModal(editTemplate,user)" class="border-0 text-info"><i class="fas fa-edit"></i></button>
                    <button (click)="deleteModal(template)" class="border-0 text-danger"><span><i class="fas fa-trash-alt"></i></span></button> {{user.email}}
                </td>
                <td>
                    {{user.assignedRole}}
                </td>
                <ng-template #template>
                    <div class="modal-body text-center">
                        <p>Do you want to delete user &nbsp;<strong><b>{{user.email}}</b></strong></p>
                        <button type="button" class="btn btn-success mr-3" (click)="deleteUser(user.id)">Yes</button>
                        <button type="button" class="btn btn-danger" (click)="decline()">No</button>
                    </div>
                </ng-template>
                <ng-template #editTemplate>
                    <div class="modal-header">
                        <h4 class="modal-title pull-left">Update User</h4>
                        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                              <span aria-hidden="true">&times;</span>
                            </button>
                    </div>
                    <div class="modal-body">
                        <form [formGroup]="updateForm" class="update-user-form" autocomplete="off">
                            <div class="form-group position-relative">
                                <input type="text" placeholder="Name" required formControlName="name" class="form-control border-0 ">
                                <div *ngIf="form.name.invalid && formError" class="text-danger text-small text-left d-block pl-4">
                                    <span class="Poppins-medium error-message" *ngIf="form.name.errors?.required">{{FormMessage.requiredError}}</span>
                                </div>
                            </div>
                            <div class="form-group position-relative">
                                <input type="email" placeholder="Email" required class="form-control border-0 mb-2" formControlName="email">
                                <div *ngIf="form.email.invalid && formError" class="text-danger text-small text-left d-block pl-4">
                                    <span class="Poppins-medium error-message" *ngIf="form.email.errors?.required">{{FormMessage.requiredError}}</span>
                                    <span class="Poppins-medium error-message" *ngIf="form.email.errors?.email">{{FormMessage.emailError}}</span>
                                </div>
                            </div>
                            <!-- <div class="form-group position-relative">
                                    <input type="password" name="pass" placeholder="Password" required class="form-control border-0 mb-2" formControlName="password">
                                    <div *ngIf="form.password.invalid && formError" class="text-danger text-small text-left d-block pl-4">
                                        <span class="Poppins-medium error-message" *ngIf="form.password.errors.required "> {{FormMessage.requiredError}}</span>
                                        <span class="Poppins-medium error-message" *ngIf="form.password.errors?.minlength">{{FormMessage.PasswordLengthError}}</span>
                                    </div>
                                </div> -->
                            <div class="form-group position-relative">
                                <select class="custom-select form-control border-0" formControlName="role">
                                      <option value=null>Choose role</option>
                                      <option *ngFor="let role of roles" [ngValue]="role.id">{{role.name}}</option>
                                  </select>
                                <span class="position-absolute select-caret fas fa-caret-down"></span>
                                <div class="text-danger text-small text-left d-block pl-4 Poppins-medium error-message" *ngIf="formError && form.role.errors?.required">
                                    {{FormMessage.Roleerror}}
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-end mt-5">
                                <div class="d-flex align-items-center justify-content-center forget-btn-sec">
                                    <button type="submit" [ngClass]="{'spinner-open': button}" [disabled]="button" class="btn btn-brand btn-pill default-btn " (click)="updateUser()">Update</button>
                                    <button class="btn btn-brand btn-pill cancel-btn ml-3" (click)="decline()">Cancel</button>
                                </div>
                            </div>

                        </form>
                    </div>
                    <!-- <div class="modal-footer"> -->
                    <!-- <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button> -->
                    <!-- <button type="button" class="btn btn-success mr-3">Update</button>
                            <button type="button" class="btn btn-danger" (click)="decline()" >Cancle</button>

                          </div> -->
                </ng-template>
            </tr>
        </tbody>
    </table>
    <pagination-controls (pageChange)="pagechanged($event)" (pageBoundsCorrection)="pagechanged($event)" maxSize="5"></pagination-controls>
    <!-- </cdk-virtual-scroll-viewport> -->
</div>
<!-- </div> -->
