import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ToasterconfigService } from 'src/app/shared/services/toasterconfig.service';
import { ErrorMessage } from '../../../assets/data/error'
import { Router } from '@angular/router';
import { FormvalidationService } from 'src/app/shared/services/formvalidation.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  //global variables declaration
  public formError: boolean;
  public forgetPasswordForm: FormGroup;
  public forgetPasswordVerifyFrom: FormGroup;
  public button: boolean = false;
  public formMessage: any = ErrorMessage;
  public userEmail: string = "";
  public forgetformDisplay: boolean = true;


  constructor(private _fb: FormBuilder,
    private _apiService: ApiService,
    private _toaster: ToastrService,
    private _formValidatior: FormvalidationService,
    private _router: Router,
    private _toasterconfig: ToasterconfigService) { }

  ngOnInit() {

    this.formError = false;
    this.button = false;
    this.forgetPasswordForm = this._fb.group({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
    this.forgetPasswordVerifyFrom = this._fb.group({
      email: new FormControl(this.userEmail, [Validators.required, Validators.email]),
      otp: new FormControl(null, Validators.required),
      newPassword: new FormControl(null, [Validators.required, Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d).+$/), Validators.minLength(8)])
      , newConfirmPassword: new FormControl(null, [Validators.required])
    },
      {
        validator: this._formValidatior.MatchPassword('newPassword', 'newConfirmPassword')

      }

    );
  }

  //form group controls
  get form() {
    return this.forgetPasswordForm.controls;
  }
  get forgetForm() {
    return this.forgetPasswordVerifyFrom.controls;
  }
  // this method used to request email to set password

  public request(): void {
    this.formError = true;
    if (this.forgetPasswordForm.valid) {
      // this.button = true;
      console.log(this.forgetPasswordForm.value.email)
      document.body.classList.add('loaderclass')
      try {
        this._apiService.forgetPassword({ "email": this.forgetPasswordForm.value.email }).subscribe(response => {
          if (response) {
            this._toaster.success("Email verification link sent successfully");
            this.userEmail = this.forgetPasswordForm.value.email;
            this.forgetformDisplay = false;
            this.ngOnInit();
          }
        }, error => {
          this._toaster.error("Invalid request");
          console.log(error)
          // this.button = false;
          document.body.classList.remove('loaderclass');
        })

      } catch (error) {
        console.log(error);
      }
      console.log(this.forgetPasswordForm.value.email)
    }
  }

  public verify(): void {
    this.formError = true;
    if (this.forgetPasswordVerifyFrom.valid) {
      console.log(this.forgetPasswordVerifyFrom.value);
      try {
        this._apiService.forgetPasswordVerify({
          "email": this.forgetPasswordVerifyFrom.value.email,
          "otp": this.forgetPasswordVerifyFrom.value.otp,
           "new_password": this.forgetPasswordVerifyFrom.value.newPassword,
          "confirm_password": this.forgetPasswordVerifyFrom.value.confirmPassword
        }).subscribe(
          response => {
            console.log(response);
            this._toaster.success("Password has been changed Successfully");
            this._router.navigateByUrl('/login');
          }, error => {
            console.log(error);
            this._toaster.error("You have Entered invalid Otp check it and try again");
          }
        )

      } catch (error) {
        console.log(error);
      }

    }


  }

}

