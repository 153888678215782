<div class="user-table-wrapper">
    <h4 class="form-title text-center mb-4">BookMarks</h4>
    <div class="scroll-table" light malihu-scrollbar [scrollbarOptions]="darkScrollbarOptions">
        <!-- <ng-scrollbar > -->
        <table class="table table-borderless">
            <thead>
                <tr>
                    <th class="text-center">title</th>
                    <th class="text-center">Url</th>
                </tr>
            </thead>
            <tbody>

                <tr *ngFor="let bookmark of bookMarks|paginate:{ itemsPerPage: 10, currentPage: viewPageNO }; let i =index">
                    <td>
                        <button (click)="editModal(editTemplate,bookmark)" class="border-0 text-info"><i
                class="fas fa-edit"></i></button>
                        <button (click)="deleteModal(template)" class="border-0 text-danger"><span><i
                  class="fas fa-trash-alt"></i></span></button> {{bookmark.title}}&nbsp;
                    </td>
                    <td (click)="bookmarkSelected(bookmark)" style="cursor:pointer;">
                        {{bookmark.url}}
                    </td>
                    <ng-template #template>
                        <div class="modal-body text-center">
                            <p>Do you want to delete Bookmark &nbsp;<strong><b>{{bookmark.title}}</b></strong></p>
                            <button type="button" class="btn btn-success mr-3" (click)="deleteBookmark(bookmark.id)">Yes</button>
                            <button type="button" class="btn btn-danger" (click)="decline()">No</button>
                        </div>
                    </ng-template>
                    <ng-template #editTemplate>
                        <div class="modal-header">
                            <h4 class="modal-title pull-left">Update bookmark</h4>
                            <button (click)="modalRef.hide()" type="button" class="close pull-right" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group position-relative">
                                <input type="text" placeholder="title" [(ngModel)]="UpdatedBookmarkTitle" class="form-control border-0 ">
                            </div>
                            <div class="form-group position-relative">
                                <input type="text" placeholder="URL" [ngModel]="updatedBookmarkUrl" class="form-control border-0 ">
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-success mr-3" (click)="updateBookmark()">Update</button>
                            <button type="button" class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
                        </div>
                    </ng-template>
                </tr>
            </tbody>
        </table>

        <!-- </ng-scrollbar> -->
    </div>
    <pagination-controls (pageChange)="pagechanged($event)" (pageBoundsCorrection)="pagechanged($event)" maxSize="5"></pagination-controls>
</div>