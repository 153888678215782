import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './core/login/login.component';
import { ForgotPasswordComponent } from './core/forgot-password/forgot-password.component';
import { HeaderComponent } from './shared/header/header.component';
import { ChangePasswordComponent } from './shared/change-password/change-password.component';
import { CreateNewUserComponent } from './features/dashboard/create-new-user/create-new-user.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { UpdateDataComponent } from './features/dashboard/update-data/update-data.component';
import { UserListComponent } from './features/dashboard/user-list/user-list.component';
import { AuthGuard } from './shared/services/auth.guard';
import { PageNotFoundComponent } from './features/page-not-found/page-not-found.component';
import { BookmarksComponent } from './features/dashboard/bookmarks/bookmarks/bookmarks.component';


const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent, pathMatch: "full" },

  // { path: 'header', component: HeaderComponent},

  {
    path: 'dashboard', canActivateChild: [AuthGuard], canActivate: [AuthGuard], component: DashboardComponent,
    children: [
      { path: "bookmark", component: BookmarksComponent },
      { path: "create-new-user", component: CreateNewUserComponent },
      { path: "update-data", component: UpdateDataComponent },
      { path: "user-list", component: UserListComponent },
      { path: "change-password", component: ChangePasswordComponent }
    ],
  },
  { path: "page-not-found", canActivate: [AuthGuard], component: PageNotFoundComponent }
  ,
  { path: "**", canActivate: [AuthGuard], component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }


