import { Component, OnInit, VERSION, TemplateRef, AfterViewInit, Input } from '@angular/core';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { ApiService } from 'src/app/shared/services/api.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ErrorMessage } from '../../../../assets/data/error';



@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, AfterViewInit {

  @Input() maxSize: number;

  public angularVersion: string = VERSION.full;
  public ngxMalihuScrollbarVersion: string = '9.0.0';
  public modalRef: BsModalRef;

  // malihu scrollbar options
  public darkScrollbarOptions: object = { axis: 'x', theme: 'dark' };
  public lightScrollbarOptions: object = { axis: 'x', theme: 'light' };
  public users: any;
  public userdata;
  public message: string;
  public updateForm: FormGroup;
  public FormMessage = ErrorMessage;
  public formError: boolean;
  public roleResponseData: any;
  public roles: any;
  public editUser: any;
  public button: boolean;
  public perpage: number = 50;
  public page: number = 1;
  public index: number = 1;
  public viewPageNO: number = 1;
  public curretpageHitApi: number = 1;




  constructor(
    private mScrollbarService: MalihuScrollbarModule,
    private _apiservice: ApiService,
    private modalService: BsModalService,
    private _fb: FormBuilder,
    private _toaster: ToastrService) { }

  public ngOnInit(): void {
    this.formError = false;


  }
  public ngAfterViewInit():void {

    this._apiservice.getusers(this.perpage, this.page).subscribe(success => {
      this.users = success;
      console.log("success Data is", this.users);
      this.userdata = this.users.data.result;
    }, error => {
      console.log("error at userlist ", error);
    });
    this._apiservice.getRoles().subscribe(response => {
      this.roleResponseData = response;
      // console.log("user roles", this.roleResponseData.data);
      this.roles = this.roleResponseData.data;
    })

  }
  get form() {
    return this.updateForm.controls;
  }
  public deleteUser(i: number): void {
    //here selected user will deleted
    console.log(" delete user working");
    try {
      this._apiservice.deleteUser(i).subscribe(response => {
        if (response) {
          console.log(response)
          console.log("deleted successfully");
          this._toaster.success("User deleted sucessfully!!")
          this.ngAfterViewInit();
          this.modalRef.hide();
        }
      }, error => {
        console.log(error);
      })
    } catch (error) {
      console.log(error);
    }
  }


  // Delete Model Open
  public deleteModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm deleteUserModal' });
  }
  // Decline Delete User
  public decline(): void {
    this.modalRef.hide();
  }
  public editModal(editTemplate: TemplateRef<any>, user: object): void {
    console.log(user);
    this.editUser = user
    this.updateForm = this._fb.group({
      id: new FormControl(this.editUser.id),
      name: new FormControl(this.editUser.name, Validators.required),
      email: new FormControl(this.editUser.email, [Validators.required, Validators.email]),
      role: new FormControl(null, [Validators.required])
    })
    this.modalRef = this.modalService.show(editTemplate, { class: 'modal-md editUserModal' });
  }
  public updateUser(): void {
    this.formError = true
    if (this.updateForm.valid) {
      this.button = true;
      document.body.classList.add('loaderclass');

      console.log("form data is", this.updateForm.value);
      try {
        this._apiservice.updateUser(this.updateForm.value.id,
          { "name": this.updateForm.value.name, "email": this.updateForm.value.email, "roleId": this.updateForm.value.role })
          .subscribe(response => {
            console.log(response);
            this._toaster.success("User Updated successfully");
            this.ngAfterViewInit();
            this.modalRef.hide();
            this.button = false;
            document.body.classList.remove('loaderclass');
          }, error => {
            console.log(error);
            this.button = false;
            document.body.classList.remove('loaderclass');
          })
      }
      catch (error) {
        console.log(error);

      }

    }

  }

  // public onscroll(event) {
  //   console.log("user list index", event);
  //   if (event == 4 * this.index) {
  //     this.page = this.page + 1;
  //     this._apiservice.getusers(this.perpage, this.page).subscribe(response => {
  //       console.log(response);
  //       this.roleResponseData = response;
  //       let data = this.roleResponseData.data.result;
  //       this.userdata = [...this.userdata, ...data];
  //       console.log(this.roles);
  //       this.index++;

  //     })
  //   }

  // }

  public pagechanged(event) {
    console.log(event)
    this.viewPageNO = event;
    if (event == 5* this.index && event > this.curretpageHitApi) {
      this.curretpageHitApi = event;
      this.page = this.page + 1;
      this._apiservice.getusers(this.perpage, this.page).subscribe(response => {
        console.log(response);
        this.roleResponseData = response;
        let data = this.roleResponseData.data.result;
        this.userdata = [...this.userdata, ...data];
        this.index++;
      })
    }
  }

}
