import { Component, OnInit, ɵConsole } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService,IndividualConfig } from 'ngx-toastr';
import { FormvalidationService } from '../services/formvalidation.service';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';
import { ErrorMessage } from '../../../assets/data/error'



@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit {
  public formError: boolean;
  public changePasswordForm: FormGroup;
  public userChangeData: object;
  public formMessage = ErrorMessage;
  public button: boolean = false;

  public options:IndividualConfig ;

  constructor(private _toasterservice: ToastrService,
    private _fb: FormBuilder,
    private _formValidatior: FormvalidationService,
    private _api: ApiService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.formError = false;
    this.changePasswordForm = this._fb.group({
      currentPassword: new FormControl(null, [Validators.required, Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d).+$/)]),
      newPassword: new FormControl(null, [Validators.required,
      Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d).+$/), Validators.minLength(8)]),
      newConfirmPassword: new FormControl(null, [Validators.required])
    },
      {
        validator: this._formValidatior.MatchPassword('newPassword', 'newConfirmPassword')
      }
    );
  }
  get form() {
    return this.changePasswordForm.controls;
  }

  // functoin to submit form and password change

  public passwordChange(): void {
    this.formError = true;
    if (this.changePasswordForm.valid) {
      try {
        this.button = true;
        this.userChangeData = {
          "current_password": this.changePasswordForm.value.currentPassword,
          "new_password": this.changePasswordForm.value.newPassword, "new_confirm_password": this.changePasswordForm.value.newConfirmPassword
        };
        this._api.changepassword(this.userChangeData).subscribe(response => {
          console.log(response);
          if (response) {
            this.button = false;
            this._toasterservice.success("password changedsucessfuly");
            this.ngOnInit();
          }
        }, error => {
          this.button = false;
          this._toasterservice.error(error.errors.current_password);
        })
      }
      catch (error) {
        console.log(error);
      }


    }
  }
}
