import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorMessage } from '../../../assets/data/error';
import { ToasterconfigService } from 'src/app/shared/services/toasterconfig.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  //declaring global Variables
  public formError: boolean = false;
  public userData: Array<any>;
  public errorMessage: string;
  public loginForm: FormGroup;
  public response: any;
  public button: boolean = false;
  public FormMessage: any = ErrorMessage;
  public returnUrl: string;

  constructor(
    private _toasterconfig:ToasterconfigService,
    private _apiservice: ApiService,
    private _toaster: ToastrService,
    private _route: ActivatedRoute,
    private _fb: FormBuilder,
    private _router: Router,
  ) { }


  ngOnInit() {
    if (this._apiservice.loggedIn){
      this._router.navigate(['/dashboard']);
   }
    //Declaring FormGroup for login-form
    this.loginForm = this._fb.group({
      email: new FormControl(null, [Validators.required,
      Validators.email]),
      password: new FormControl(null, [Validators.required,
      Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d).+$/),
      Validators.minLength(8)])
    });


  }

  //  event to handle form controls
  get form() {
    return this.loginForm.controls;
  }


  /*method to user login */

  public signIn(): void {
    this.formError = true;
    if (this.loginForm.valid) {
      this.button = true;
      document.body.classList.add("loaderclass");
      this._apiservice.userlogin({ "email": this.loginForm.value.email, "password": this.loginForm.value.password }).subscribe(
        success => {
          if (success) {
            this.response = success;
            console.log(this.response)
            // if (this.response.data.roleType && this.response.data.access_token)

              localStorage.setItem('token',JSON.stringify(this.response));


            // console.log(this.response.data.access_token);
            this._toaster.success("Login successfull");
            // this._toasterconfig.showToast('message','login success','success');
            this._router.navigateByUrl('/dashboard')
          }
        }, error => {
          this.errorMessage = error,
            console.log("Error is", this.errorMessage)
          this._toaster.error("username and password mismatch");
          // this._toasterconfig.showToast('message',"loginFailed!",'error');
          document.body.classList.remove("loaderclass");
          this.button = false;
        });
    }
  }
}
