import { Injectable } from '@angular/core';
import { CanActivate,Router, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return true;
  // }

  constructor(private _apiservice: ApiService,
    private _router: Router,
) { }
  canActivate(): boolean {
    // console.log("canactivate working");
    if (this._apiservice.loggedIn()) {
      // console.log(this._apiservice);
      return true;
    } else {
      // console.log(this._apiservice);
      this._router.navigateByUrl('/login');
      return false;
    }

  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // console.log("**********",state.url);
    if(state.url=="/dashboard/change-password"||state.url=="/dashboard/bookmark"){
      return true;
    }
else{
  if (this._apiservice.checkAdmin()===true)// it will chexk for user is admin
     {
      return true; // allowed every route
    }
    else {
      this._router.navigateByUrl('/dashboard')
      return false; // not allowed to any route
    }
  }
}


}
