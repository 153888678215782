<app-header></app-header>
<div class="main-container">
    <div class="dashboard-wrapper">
        <div class="position-relative px-4">
            <div class="search-filter position-relative mr-auto mx-sm-auto">
                <input type="text" placeholder="Search..." id="search" class="filter-search w-100" [(ngModel)]=" search " (keyup)="searchChange()" (keyup.enter)="myheader()" />
                <span class="icon search-icon position-absolute " (click)="myheader() ">
          <i class="fas fa-search "></i>
        </span>
                <!-- <span class="icon close-icon position-absolute" (click)="intialView()">
          <i class="fas fa-times "></i>
        </span> -->
                <span *ngIf="bookMarkButton" class="icon close-icon position-absolute" (click)="bookMarkTemplate(bookMark)">
          <i class="fas fa-bookmark "></i>
        </span>
            </div>

            <!-- <div class="view-buttons text-right position-absolute ">
                <span class="mr-3 " (click)="listview() ">
          <i class="fas fa-th-list "></i>
        </span>
                <span class=" " (click)="gridview() ">
          <i class="fas fa-th "></i>
        </span>
            </div>
        </div> -->
            <!-- <div class="scroll-table " light malihu-scrollbar [scrollbarOptions]="darkScrollbarOptions "> -->

            <div *ngIf="listView " class="dashboard-table-wrapper mt-4">

                <!-- <table class="table table-borderless dashboard-table ">
                    <thead>
                        <tr>
                            <th>ID </th>
                            <th>Haus</th>
                            <th>Artikel_KEY</th>
                            <th>Artikel_Nummer</th>
                            <th>Artikel_Status</th>
                            <th>Artikelbezeichung</th>
                            <th>Key_KH </th>
                            <th>LieferantArtikelbezeichnung</th>
                            <th>Lieferanteen_Artikelnummer</th>
                            <th>Warengruppen_status</th>
                            <th>Level 1</th>
                            <th>Level 2</th>
                            <th>Level 3</th>
                            <th>Level 4</th>
                            <th>Level 5</th>
                            <th>Level 6</th>
                            <th>Level 7</th>
                            <th>Level 8</th>
                            <th>Level 9</th>
                            <th>Level 10</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *cdkVirtualFor="let tableData of jsonTableData; let i=i ndex ">
                            <td>{{tableData.id}}</td>
                            <td>{{tableData.Haus}}</td>
                            <td>{{tableData.Artikel_KEY}}</td>
                            <td>{{tableData.Artikel_Nummer}}</td>
                            <td>{{tableData.Artikel_Status}}</td>
                            <td>{{tableData.Artikelbezeichung}}</td>
                            <td>{{tableData.Key_KH}}</td>
                            <td>{{tableData.LieferantArtikelbezeichnung}}</td>
                            <td>{{tableData.Lieferanteen_Artikelnummer}}</td>
                            <td>{{tableData.Warengruppen_status}}</td>
                            <td>{{tableData.Level1}}</td>
                            <td>{{tableData.Level2}}</td>
                            <td>{{tableData.Level3}}</td>
                            <td>{{tableData.Level4}}</td>
                            <td>{{tableData.Level5}}</td>
                            <td>{{tableData.Level6}}</td>
                            <td>{{tableData.Level7}}</td>
                            <td>{{tableData.Level8}}</td>
                            <td>{{tableData.Level9}}</td>
                            <td>{{tableData.Level10}}</td>
                        </tr>
                    </tbody>
                </table>-->
                <ngx-datatable #myTable class="bootstrap ngx-datatable " [columns]="_apiservice.checkAdmin() ? columnsAdmin:columnsUser" [headerHeight]="50 " [loadingIndicator]="isLoading " [scrollbarV]="true " [scrollbarH]="true " [rowHeight]="50 " [footerHeight]="50
              " (sort)="myheader($event)" (page)="intialCall ? intialView() :setpage($event,search,columnSelected,sortby);" [count]="totalCount" [rows]="jsonTableData " [externalPaging]="true">
                </ngx-datatable>
            </div>
            <!-- </div> -->
            <div *ngIf="gridView" class="dashboard-card-wrapper mt-4">

                <!-- <cdk-virtual-scroll-viewport itemSize="75" (scrolledIndexChange)="onscroll($event)"> -->
                <div class="dashboardCardItems ">
                    <div class="dashboardCardItem " *ngFor=" let tableData of jsonTableData let i=index ">
                        <h2 class="dashboardCardItem-title ">ID:{{ tableData.id}}</h2>
                        <ul class="list-unstyled mb-1 ">
                            <li>
                                <span class="text-white ">Key_KH : </span>{{ tableData.Key_KH }}
                            </li>
                            <li><span class="text-white ">Haus: </span>{{ tableData.Haus }}</li>
                            <li>
                                <span class="text-white ">Artikel_Nummer : </span> {{ tableData.Artikel_Nummer }}
                            </li>
                            <li>
                                <span class="text-white ">Artikel_KEY : </span>{{ tableData.Artikel_KEY }}
                            </li>
                        </ul>
                        <button class="float-right border-0 dashboardCardItemBtn p-0 small " (mouseenter)="openModal(template, i) ">
              show more
            </button>
                    </div>
                </div>
                <!-- </cdk-virtual-scroll-viewport> -->
            </div>

        </div>
        <ng-template #template>
            <div class="modal-header ">
                <h4 class="modal-title pull-left ">ID:{{ selectedCardData.id }}</h4>
                <button type="button " class="close pull-right " aria-label="Close " (click)="modalRef.hide() ">
          <span aria-hidden="true ">&times;</span>
        </button>
            </div>
            <div class="modal-body ">
                <div class="row ">
                    <div class="col-6 ">
                        <ul class="list-unstyled ">
                            <li class="dashboardCardItem-details mt-1 ">
                                <span class="text-white ">Key_KH : </span>
                                <span class="d-block mt-2 ">{{ selectedCardData.Key_KH }}</span>
                            </li>
                            <li class="dashboardCardItem-details mt-1 ">
                                <span class="text-white ">Haus : </span>
                                <span class="d-block mt-2 ">{{ selectedCardData.Haus }}</span>
                            </li>
                            <li class="dashboardCardItem-details mt-1 ">
                                <span class="text-white ">Warengruppen_status : </span>
                                <span class="d-block mt-2 ">{{
              selectedCardData.Warengruppen_status
            }}</span>
                            </li>
                            <li class="dashboardCardItem-details mt-1 ">
                                <span class="text-white ">Artikel_Status : </span>
                                <span class="d-block mt-2 ">{{
              selectedCardData.Artikel_Status
            }}</span>
                            </li>
                            <li class="dashboardCardItem-details mt-1 ">
                                <span class="text-white "> Artikel_Key : </span>
                                <span class="d-block mt-2 ">{{ selectedCardData.Artikel_Key }}</span>
                            </li>
                            <li class="dashboardCardItem-details mt-1 ">
                                <span class="text-white ">Artikel_Nummer : </span>
                                <span class="d-block mt-2 ">{{
              selectedCardData.Artikel_Nummer
            }}</span>
                            </li>

                            <li class="dashboardCardItem-details mt-1 ">
                                <span class="text-white ">Artikelbezeichung : </span>
                                <span class="d-block mt-2 ">{{
              selectedCardData.Artikelbezeichung
            }}</span>
                            </li>
                            <li class="dashboardCardItem-details mt-1 ">
                                <span class="text-white ">Lieferanteen_Artikelnummer :</span>
                                <span class="d-block mt-2 ">{{
              selectedCardData.Lieferanteen_Artikelnummer
            }}</span>
                            </li>
                            <li class="dashboardCardItem-details mt-1 ">
                                <span class="text-white ">LieferantArtikelbezeichnung :</span>
                                <span class="d-block mt-2 ">{{
              selectedCardData.LieferantArtikelbezeichnung
            }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6 ">
                        <ul class="list-unstyled mt-1 ">
                            <li class="dashboardCardItem-details mt-1 ">
                                <span class="text-white ">Level0 : </span>
                                <span class="d-block mt-2 ">{{ selectedCardData.Level0 }}</span>
                            </li>

                            <li class="dashboardCardItem-details mt-1 ">
                                <span class="text-white ">Level1 : </span>
                                <span class="d-block mt-2 ">{{ selectedCardData.Level1 }}</span>
                            </li>
                            <li class="dashboardCardItem-details mt-1 ">
                                <span class="text-white ">Level2 : </span>
                                <span class="d-block mt-2 ">{{ selectedCardData.Level2 }}</span>
                            </li>
                            <li class="dashboardCardItem-details mt-1 ">
                                <span class="text-white ">Level3 : </span>
                                <span class="d-block mt-2 ">{{ selectedCardData.Level3 }}</span>
                            </li>
                            <li class="dashboardCardItem-details mt-1 ">
                                <span class="text-white ">Level4 : </span>
                                <span class="d-block mt-2 ">{{ selectedCardData.Level4 }}</span>
                            </li>
                            <li class="dashboardCardItem-details mt-1 ">
                                <span class="text-white ">Level5 : </span>
                                <span class="d-block mt-2 ">{{ selectedCardData.Level5 }}</span>
                            </li>
                            <li class="dashboardCardItem-details mt-1 ">
                                <span class="text-white ">Level6 : </span>
                                <span class="d-block mt-2 ">{{ selectedCardData.Level6 }}</span>
                            </li>
                            <li class="dashboardCardItem-details mt-1 ">
                                <span class="text-white ">Level7 : </span>
                                <span class="d-block mt-2 ">{{ selectedCardData.Level7 }}</span>
                            </li>
                            <li class="dashboardCardItem-details mt-1 ">
                                <span class="text-white ">Level8 : </span>
                                <span class="d-block mt-2 ">{{ selectedCardData.Level8 }}</span>
                            </li>
                            <li class="dashboardCardItem-details mt-1 ">
                                <span class="text-white ">Level9 : </span>
                                <span class="d-block mt-2 ">{{ selectedCardData.Level9 }}</span>
                            </li>
                            <li class="dashboardCardItem-details mt-1 ">
                                <span class="text-white ">Level10 : </span>
                                <span class="d-block mt-2 ">{{ selectedCardData.Level10 }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template #bookMark>
            <div class="modal-header">
                <h4 class="modal-title pull-left">Add Bookmark</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="form-group position-relative">
                    <input type="text" [(ngModel)]="BookMarkTitle" placeholder="title" class="form-control border-0 ">
                </div>
                <div class="form-group position-relative">
                    <input type="text" disabled placeholder="URL" [(ngModel)]="this.bookMarkUrl" class="form-control border-0 ">
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success mr-3" (click)="bookMarkSave(BookMarkTitle,bookMarkUrl)">Save</button>
                <button type="button" class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
            </div>
        </ng-template>