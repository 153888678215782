import { Component, OnInit, VERSION, TemplateRef, AfterViewInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { MalihuScrollbarModule, MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { ApiService } from 'src/app/shared/services/api.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { Product } from 'src/app/shared/services/interface';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  public columnsAdmin: object = [{ prop: "id", name: "Id" }, { prop: "Key_KH", name: "Key_KH" }, { prop: "Haus", name: "Haus" }, { prop: "Warengruppen_status", name: "WarengruppenStatus" }, { prop: "Artikel_Status", name: "ArtikelStatus" }, { prop: "Artikel_KEY", name: "ArtikelKEY" }, { prop: "Artikel_Nummer", name: "ArtikelNummer" }, { prop: "Artikelbezeichung", name: "Artikelbezeichung" }, { prop: "Lieferanteen_Artikelnummer", name: "LieferanteenArtikelNummer" },
  { prop: "LieferantArtikelbezeichnung", name: "LieferantArtikelbezeichnung" },
  { prop: "Level0", name: "Level0" }, { prop: "Level1", name: "Level1" }, { prop: "Level2", name: "Level2" }, { prop: "Level3", name: "Level3" }, { prop: "Level4", name: "Level4" }, { prop: "Level5", name: "Level5" }, { prop: "Level6", name: "Level6" }
    , { prop: "Level7", name: "Level7" }, { prop: "Level8", name: "Level8" }, { prop: "Level9", name: "Level9" }, { prop: "Level10", name: "Level10" }];;


  public columnsUser: object = [{ prop: "id", name: "Id" }, { prop: "Warengruppen_status", name: "WarengruppenStatus" }, { prop: "Artikel_Status", name: "ArtikelStatus" }, { prop: "Artikel_KEY", name: "ArtikelKEY" }, { prop: "Artikel_Nummer", name: "ArtikelNummer" }, { prop: "Artikelbezeichung", name: "Artikelbezeichung" }, { prop: "Lieferanteen_Artikelnummer", name: "LieferanteenArtikelNummer" },
  { prop: "LieferantArtikelbezeichnung", name: "LieferantArtikelbezeichnung" },
  { prop: "Level0", name: "Level0" }, { prop: "Level1", name: "Level1" }, { prop: "Level2", name: "Level2" }, { prop: "Level3", name: "Level3" }, { prop: "Level4", name: "Level4" }, { prop: "Level5", name: "Level5" }, { prop: "Level6", name: "Level6" }
    , { prop: "Level7", name: "Level7" }, { prop: "Level8", name: "Level8" }, { prop: "Level9", name: "Level9" }, { prop: "Level10", name: "Level10" }];;

  public isLoading: boolean;
  public angularVersion = VERSION.full;
  public ngxMalihuScrollbarVersion = '9.0.0';
  public modalRef: BsModalRef;
  public jsonTableData: Product[] = [];
  public search: string = "";
  public listView: boolean = true;
  public gridView: boolean = false;
  public selectedCardData: any;
  public successData: any;
  public successData2: any;
  public sortsArray: Array<string> = ['ASC', 'DESC'];
  public sortby: string;
  public bookMarkUrl: string = "";
  public BookMarkTitle: string;
  public bookMarkButton: boolean = false;
  public bookmarkSearch: string;
  public bookmarkRequested:any;

  public keyword: string = 'ArticleName';
  public filtereddat;
  public totalCount;
  public perpage: number = 55; // this is for Api per page
  public viewPerPage: 10;
  public currentPage: number = 1;
  public pageNO: number;
  public i = 1;
  public index: number;
  public columnSelected: string;
  public intialCall: boolean = true;
  // malihu scrollbar options
  public darkScrollbarOptions: object = { axis: 'x', theme: 'dark' };
  public lightScrollbarOptions: object = { axis: 'y', theme: 'light' };
  @ViewChild('myTable') table;
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
  constructor(private mScrollbarService: MalihuScrollbarModule,
    private _mscrollbar: MalihuScrollbarService,
    public _apiservice: ApiService,
    private modalService: BsModalService,
    private _router: Router, private _activaterouter: ActivatedRoute,
    private _elementRef: ElementRef,
    private _toaster: ToastrService) {

  }
  ngAfterViewInit() {
    this._apiservice.uploadprduct$.subscribe(issproductuploaded => {
      issproductuploaded ? this.intialView() : '';
    })
    this._apiservice.bookmarkSelected$.subscribe(data => {
      this.bookmarkRequested=data;
      this.search=this.bookmarkRequested.search;
      console.log(this.search);
      this.bookmarkRequested.boolean?this.myheader():'';
    })
  }
  ngOnInit() {

  }

  // method to call api when page load with pageno1 and perpage=55

  public intialView(): void {
    this.jsonTableData.length = 0;
    this.pageNO = 1;
    this.search = "";
    this._apiservice.getProductsByPage(this.perpage, this.pageNO, '', this.columnSelected, this.sortby).subscribe(success => {
      console.log(success)
      this.intialCall = false;
      this.successData = success
      this.totalCount = this.successData.data.total;
      this.jsonTableData = this.successData.data.result;

    }, error => {
      console.log(error);
    })

  }

  // to display data in List view

  public listview(): void {
    // console.log("listview");
    this.listView = true;
    this.gridView = false;
  }

  // to display data in Grid view

  public gridview(): void {
    // console.log("Grid view");
    this.listView = false;
    this.gridView = true;

  }

  public openModal(template: TemplateRef<any>, i: number): void {
    const selected = i;
    this.selectedCardData = this.jsonTableData[selected];
    console.log("this isb the selected one", this.selectedCardData, typeof this.selectedCardData)

    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-lg' })
    );
  }

  // this method is for search bar

  public searchChange(): void {
    // console.log("key down is working", this.search.length, this.search)
    if (this.search == "" && this.search.length == 0 ) {
      this.search = "";
      this.bookMarkButton = false;
      this.intialView();
    }
  }

  // this is for table pagination and Important method
  public setpage(event?: any, search?: any, columnSelected?: string, sortby?: string): void {

    // console.log(event)
    // console.log("on change working", this.jsonTableData.length, this.currentPage, Math.floor(this.jsonTableData.length / 1),event.offset);

    if (this.jsonTableData.length == 0 || (this.jsonTableData.length >= 55 && Math.floor(this.jsonTableData.length / 11) == event.offset)) {
      // console.log("condition working")
      let columnsort;
      if (columnSelected) {
        columnsort = columnSelected;
        !event ? this.pageNO = 0 : "";
      } else {
        columnsort = null;
      }
      let offset = event ? event.offset : 0;
      let condition = offset ? offset >= this.currentPage + 4 : search;
      console.log(condition)
      if (condition || this.columnSelected) {
        if (typeof condition == 'boolean') {
          this.currentPage = !columnsort || Math.floor(this.jsonTableData.length / 11) == event.offset ? offset + 1 : 1;
          !columnsort || Math.floor(this.jsonTableData.length / 11) == event.offset ? this.pageNO++ : this.pageNO = 1;
          console.log(" in side if condition", !columnsort, Math.floor(this.jsonTableData.length / 11) == event.offset);
        }
        else {
          this.currentPage = 1;
          this.pageNO = 1;
        }
        console.log("data coming")
        this.jsonTableData.length == 0 ? this.pageNO = 1 : '';
        console.log("before api", this.jsonTableData.length, this.pageNO, this.currentPage, offset)
        this._apiservice.getProductsByPage(this.perpage, this.pageNO, search, columnsort, sortby).subscribe(
          response => {
            console.log(response);
            this.successData2 = response;
            let data = this.successData2.data.result;
            this.jsonTableData = typeof condition == 'boolean' && (!columnsort || Math.floor(this.jsonTableData.length / 11) == event.offset) ? [...this.jsonTableData, ...data] : data;
            if (search != "" && this.jsonTableData.length > 0) {
              this.bookMarkButton = true;
            }
            // console.log(this.jsonTableData);
          }, error => {
            console.log(error);
          }
        )
      }
    }
  }


  // this method is virtual scroll bar
  public onscroll(event) {
    console.log(event)

    // if (event == 20 * this.i) {
    //   this.pageNO = this.pageNO + 1;
    //   this._apiservice.getProductsByPage(this.perpage, this.pageNO, this.search, this.columnSelected).subscribe(
    //     response => {
    //       this.successData2 = response;
    //       let data = this.successData2.data.result;
    //       this.jsonTableData = [...this.jsonTableData, ...data];
    //       console.log(this.jsonTableData)
    //       this.i = this.i + 1;
    //     }, error => {
    //       console.log(error);
    //     }
    //   )
    // }
  }
  //this function is used to set sortby columnName and sort type

  public myheader(event?: any) {
    // console.log("sort is working", event);
    this.bookmarkSearch = this.search;
    let columnname = event ? event.column.name : '';
    let selectedsort = event ? event.newValue : '';
    this.columnSelected = columnname;
    this.sortby = selectedsort;
    this.jsonTableData.length = 0;
    this.setpage('', this.search, this.columnSelected, this.sortby);
  }

  public bookMarkSave(title, bookmarkurl) {
    console.log(title, bookmarkurl);
    if(title==undefined||bookmarkurl==""){
      this._toaster.warning("Title or url should not empty");

    }else{

      try{
        this._apiservice.bookMark(title, bookmarkurl).subscribe(response => {
          console.log(response);
          if (response) {
            this._toaster.success("Bookmark Saved Successfully");
            this.modalRef.hide();
            this.bookMarkButton = false;
            this.BookMarkTitle="";
          }
        }, error => {
          console.log(error);
          this._toaster.error("Something went wrong please try again");
        });
      }
      catch(error){
         console.log(error);
      }
    }


  }

  public bookMarkTemplate(bookmark: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(
      bookmark,
    )
    this.bookMarkUrl = "http://206.189.140.51/warengruppen/public/api/user/getproducts?search=" + this.bookmarkSearch;

  }

}
